import { SimpleSlider } from '@ext/ndspatterns/Resources/Public/Assets/Js/Components/Simpleslider/simpleslider.js';

const mediaGallerySelector = '.js-media-gallery-slider';
const paginationSelector = '.js-media-gallery__slider-pagination';
let sliderInstance;

function initSlider() {
  const sliders = document.querySelectorAll(mediaGallerySelector);
  sliders.forEach(function(sliderElem) {
    sliderInstance = new SimpleSlider(sliderElem, {allowDisableNav: true});

    if (sliderElem.querySelector(paginationSelector) != null) {
      initPagination(sliderInstance, sliderElem);

      sliderInstance.config.afterSlideCallback = (index) => {
        const paginationActiveClass = 'simpleslider-pagination-item--active';
        const element = sliderElem.querySelector('[data-item="' + index + '"]')
        if (element) {
          sliderElem.querySelector('.' + paginationActiveClass) ? sliderElem.querySelector('.' + paginationActiveClass).classList.remove(paginationActiveClass) : null;
          element.classList.add(paginationActiveClass);
        }
      };
    }

    sliderInstance.init();
  });
}

function initPagination(sliderInstance, sliderElem) {
  const buttons = sliderElem.querySelectorAll('.js-simpleslider-pagination-item')
  const event = (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) ? 'touchstart' : 'click';

  buttons.forEach((button, index) => {
    button.addEventListener(event, e => sliderInstance.goToSlide(index), false);
  })
}

if(document.querySelector(mediaGallerySelector) !== null) {
  initSlider();
}
